var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { staticClass: "mb-0", attrs: { "no-body": "" } },
        [
          _c("b-card-text", { staticClass: "p-1" }, [
            _c("div", { staticClass: "form-row justify-content-between" }, [
              _c("div", { staticClass: "col-md-2" }, [
                _c(
                  "div",
                  { staticClass: "form-group mb-md-0" },
                  [
                    _c("v-select", {
                      attrs: {
                        label: "title",
                        required: "",
                        options: _vm.perPageOptions,
                        clearable: false,
                        searchable: false,
                      },
                      on: { input: _vm.setPerPageSelected },
                      model: {
                        value: _vm.filters.perPage,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "perPage", $$v)
                        },
                        expression: "filters.perPage",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("div", { staticClass: "col-md-3" }, [
                _c("div", { staticClass: "d-flex" }, [
                  _c("div", { staticClass: "form-group mb-md-0 mr-1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.classroom,
                          expression: "filters.classroom",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.filters.classroom },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.filters,
                            "classroom",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-block",
                      staticStyle: {
                        padding: "14px !important",
                        width: "50px",
                      },
                      attrs: { type: "button" },
                      on: { click: _vm.search },
                    },
                    [
                      !_vm.btnSearch
                        ? _c("i", { staticClass: "bi bi-search" })
                        : _c("b-spinner", {
                            attrs: { small: "", variant: "light" },
                          }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "sticky-header": "",
              items: _vm.dataItems,
              responsive: "",
              fields: _vm.tableColumns,
              "primary-key": "id",
              "show-empty": "",
              "empty-text": "Nenhum registro encontrado",
            },
            scopedSlots: _vm._u([
              {
                key: "cell(name)",
                fn: function (data) {
                  return [
                    _c("div", { staticClass: "d-block text-nowrap" }, [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0",
                          staticStyle: { "font-size": "1rem" },
                        },
                        [
                          _c("i", {
                            staticClass: "bi bi-circle-fill",
                            class: data.item.status
                              ? "text-success"
                              : "text-danger",
                            staticStyle: { "font-size": "0.7rem" },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(data.item.name) +
                              " - " +
                              _vm._s(data.item.course_title) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "ul",
                        { staticClass: "list-inline mb-0" },
                        _vm._l(data.item.days, function (day, index) {
                          return _c("li", { key: index }, [
                            _c("span", [_vm._v(_vm._s(day.title))]),
                            index === data.item.days.length - 2
                              ? _c(
                                  "span",
                                  { staticStyle: { padding: "0px 3px" } },
                                  [_vm._v("e")]
                                )
                              : _vm._e(),
                            index !== data.item.days.length - 1 &&
                            index !== data.item.days.length - 2
                              ? _c(
                                  "span",
                                  { staticStyle: { padding: "0px 3px" } },
                                  [_vm._v(",")]
                                )
                              : _vm._e(),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(time)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("justTime")(data.item.start_time)) +
                        " - " +
                        _vm._s(_vm._f("justTime")(data.item.end_time)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(unit_name)",
                fn: function (data) {
                  return [
                    _c(
                      "b-badge",
                      {
                        staticClass: "d-block",
                        staticStyle: { "margin-bottom": "4px" },
                        attrs: { variant: "light-dark" },
                      },
                      [_vm._v(_vm._s(data.item.modality_name))]
                    ),
                    _c("span", { staticClass: "d-block" }, [
                      _vm._v(" " + _vm._s(data.item.shifty_title) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "cell(postponement)",
                fn: function (data) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("datePT")(data.item.postponement, false)
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(actions)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex-between-full",
                        staticStyle: { gap: "18px" },
                      },
                      [
                        _vm.$can("Grade - Excluir", "Pedagógico")
                          ? _c("feather-icon", {
                              staticClass: "cursor-pointer cursor text-danger",
                              attrs: { icon: "TrashIcon", size: "16" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDelete(item.uuid)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm.$can("Grade - Editar", "Pedagógico")
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "grids-edit",
                                    params: { uuid: item.uuid },
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "EditIcon", size: "16" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.$can("Grade - Visualizar", "Pedagógico")
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "grids-show",
                                    params: { uuid: item.uuid },
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer cursor",
                                  attrs: { icon: "MonitorIcon", size: "16" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("CustomPaginateTable", {
            attrs: {
              rowsTable: _vm.rowsTable,
              currentPage: this.filters.currentPage,
              perPage: this.filters.perPage,
              totalRows: _vm.totalRows,
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-create-grid",
            "modal-class": "modal-create",
            title: "Cadastrar Grade",
            centered: "",
            size: "lg",
            "hide-footer": "",
          },
        },
        [
          _c("h5", [
            _c("i", { staticClass: "text-danger bi bi-record-circle" }),
            _vm._v(" Escolha a turma que você deseja montar grade."),
          ]),
          _c("div", { staticClass: "alert alert-primary" }, [
            _c("div", { staticClass: "alert-body" }, [
              _vm._v(" Uma turma só poderá ter 1 grade. "),
              _c("br"),
              _vm._v(
                "Se a turma não aparecer na listagem, talvez já esteja vinculada a outra grade. "
              ),
            ]),
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c(
              "div",
              { staticClass: "col-md-10" },
              [
                _c(
                  "v-select",
                  {
                    attrs: {
                      label: "title",
                      "item-text": "title",
                      "item-value": "code",
                      placeholder: "Digite o CÓDIGO DA TURMA. Ex.: DL259",
                      options: _vm.optionsClassroomsEmptyGrid,
                    },
                    on: { search: _vm.fetchClassroomsEmptyGrid },
                    scopedSlots: _vm._u([
                      {
                        key: "option",
                        fn: function (option) {
                          return [
                            _c("span", { class: option.icon }),
                            _vm._v(" " + _vm._s(option.title) + " "),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.classUuid,
                      callback: function ($$v) {
                        _vm.classUuid = $$v
                      },
                      expression: "classUuid",
                    },
                  },
                  [
                    _c(
                      "span",
                      { attrs: { slot: "no-options" }, slot: "no-options" },
                      [_vm._v(" Nenhum registro encontrado ")]
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "col-md-2" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-block btn-primary",
                  staticStyle: { padding: "15px 10px !important" },
                  on: { click: _vm.btnCreateGrid },
                },
                [_vm._v(" Montar Grade ")]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }