<template>
  <div>
    <b-card no-body class="mb-0">
      <b-card-text class="p-1">
        <div class="form-row justify-content-between">
          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <v-select
                label="title"
                required
                v-model="filters.perPage"
                :options="perPageOptions"
                @input="setPerPageSelected"
                :clearable="false"
                :searchable="false"
              />
            </div>
          </div>
          
          <div class="col-md-3">
           <div class="d-flex">
              <div class="form-group mb-md-0 mr-1">
                <input type="text" class="form-control" v-model="filters.classroom">
              </div>
              <button
                @click="search"
                style="padding: 14px !important;width: 50px;"
                type="button"
                class="btn btn-primary btn-block"
              >
                <i v-if="!btnSearch" class="bi bi-search"></i>
                <b-spinner v-else small variant="light" />
              </button>
            </div>
          </div>
        </div>
      </b-card-text>
      
      <b-table
        sticky-header
        class="position-relative"
        :items="dataItems"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Nenhum registro encontrado"
      >
        <template #cell(name)="data">
          <div class="d-block text-nowrap">
            <p style="font-size: 1rem" class="mb-0">
              <i
                style="font-size: 0.7rem"
                class="bi bi-circle-fill"
                :class="data.item.status ? 'text-success' : 'text-danger'"
              ></i>
              {{ data.item.name }} - {{ data.item.course_title }}
            </p>
            <ul class="list-inline mb-0">
              <li v-for="(day, index) in data.item.days" :key="index">
                <span>{{ day.title }}</span>
                <span
                  v-if="index === data.item.days.length - 2"
                  style="padding: 0px 3px"
                  >e</span
                >
                <span
                  v-if="
                    index !== data.item.days.length - 1 &&
                    index !== data.item.days.length - 2
                  "
                  style="padding: 0px 3px"
                  >,</span
                >
              </li>
            </ul>
          </div>
        </template>

        <template #cell(time)="data">
          {{ data.item.start_time | justTime }} -
          {{ data.item.end_time | justTime }}
        </template>

        <template #cell(unit_name)="data">
          <b-badge
            class="d-block"
            variant="light-dark"
            style="margin-bottom: 4px"
            >{{ data.item.modality_name }}</b-badge
          >
          <span class="d-block">
            {{ data.item.shifty_title }}
          </span>
        </template>

        <template #cell(postponement)="data">
          {{ data.item.postponement | datePT(false) }}
        </template>

        <template #cell(actions)="{ item }">
          <div class="d-flex-between-full" style="gap: 18px;">
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer cursor text-danger"
              size="16"
              v-if="$can('Grade - Excluir', 'Pedagógico')"
              @click="confirmDelete(item.uuid)"
            />

            <router-link v-if="$can('Grade - Editar', 'Pedagógico')" :to="{ name: 'grids-edit', params: { uuid: item.uuid }}">
              <feather-icon
                icon="EditIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>

            <router-link v-if="$can('Grade - Visualizar', 'Pedagógico')" :to="{
              name: 'grids-show',
              params: { uuid: item.uuid },
            }">
              <feather-icon
                icon="MonitorIcon"
                class="cursor-pointer cursor"
                size="16"
              />
            </router-link>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable
        :rowsTable="rowsTable"
        :currentPage="this.filters.currentPage"
        :perPage="this.filters.perPage"
        :totalRows="totalRows"
      />
    </b-card>

    <b-modal
      id="modal-create-grid"
      modal-class="modal-create"
      title="Cadastrar Grade"
      centered
      size="lg"
      hide-footer
    >
      <h5><i class="text-danger bi bi-record-circle"></i> Escolha a turma que você deseja montar grade.</h5>
      <div class="alert alert-primary">
        <div class="alert-body">
          Uma turma só poderá ter 1 grade. <br>Se a turma não aparecer na listagem, talvez já esteja vinculada a outra grade.
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-10">
          <v-select
            label="title"
            item-text="title"
            item-value="code"
            v-model="classUuid"
            placeholder="Digite o CÓDIGO DA TURMA. Ex.: DL259"
            :options="optionsClassroomsEmptyGrid"
            @search="fetchClassroomsEmptyGrid"
          >
            <span slot="no-options"> Nenhum registro encontrado </span>
            <template v-slot:option="option">
              <span :class="option.icon"></span>
              {{ option.title }}
            </template>
          </v-select>
        </div>
        <div class="col-md-2">
          <button
            @click="btnCreateGrid"
            style="padding: 15px 10px !important"
            class="btn btn-block btn-primary"
          >
            Montar Grade
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BFormRow,
  BForm,
  BSpinner,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BFormInput,
    CustomPaginateTable,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BFormRow,
    vSelect,
    BSpinner,
    BCardText,
  },
  data() {
    return {
      perPageOptions: [
        {
          title: "100",
          value: 100,
        },
        {
          title: "200",
          value: 200,
        },
      ],
      filters: {
        classroom: "",
        currentPage: 1,
        perPage: 100,
      },
      totalRows: 0,
      rowsTable: 0,
      dataItems: [],
      btnSearch: false,
      tableColumns: [
        {
          key: "id",
          label: "ID",
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "name",
          label: "Grade",
          sortable: false,
        },
        {
          key: "time",
          label: "Horário",
          sortable: false,
          class: "text-center",
          thStyle: "width: 190px",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "unit_name",
          label: "Unidade",
          sortable: false,
          class: "text-center",
          thStyle: "width: 190px",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "postponement",
          label: "Início",
          sortable: false,
          class: "text-center",
          thStyle: "width: 110px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 100px",
        },
      ],
      optionsClassrooms: [],
      optionsClassroomsEmptyGrid: [],
      classUuid: "",
    };
  },
  methods: {
    search() {
      this.btnSearch = true;
      this.getData();
    },

    setPerPageSelected(obj) {
      this.filters.perPage = obj.value;
      this.getData();
    },

    updatePage() {
      this.getData();
    },

    btnCreateGrid() {
      if (this.classUuid) {
        this.$router.push({
          name: "grids-create",
          params: { uuid: this.classUuid.code },
        });
      } else {
        this.notifyV2(
          "Selecione a TURMA!",
          "bi-exclamation",
          "danger",
          "Antes, você precisa selecionar a turma, digitando o código."
        );
      }
    },
    async fetchClassrooms(term) {
      this.optionsClassrooms = [];
      if (term.length > 2) {
        this.optionsClassrooms = await this.$store.dispatch(
          "Classroom/search",
          term
        );
      }
    },
    async fetchClassroomsEmptyGrid(term) {
      this.optionsClassroomsEmptyGrid = [];
      if (term.length > 2) {
        this.optionsClassroomsEmptyGrid = await this.$store.dispatch(
          "Classroom/searchNoGrid",
          term
        );
      }
    },
    async getData() {
      this.$store
        .dispatch("Grid/all", {
          currentPage: this.filters.currentPage,
          perPage: this.filters.perPage,
          classroom: this.filters.classroom ?? '',
        })
        .then((data) => {
          if (data) {
            this.dataItems = data.data;
            this.rowsTable = data.data.length;
            this.totalRows = data.meta.total;
            this.filters.currentPage = data.meta.current_page;
          }
        })
        .finally(() => {
          this.btnSearch = false;
        });
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Tem certeza?",
        text: "Só será possível deletar a aula caso não tenha nenhuma disciplina vinculada.",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("Grid/delete", { uuid: uuid }).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "A aula foi deletada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-grid");
    this.$root.$off("paginate:update");
  },
  mounted() {
    this.$root.$on("paginate:update", (res) => {
      this.filters.currentPage = res._currentPage;
      this.totalRows = res._totalRows;
      this.rowsTable = res._rowsTable;
      this.filters.perPage = res._perPage;
      this.getData();
    });

    this.$root.$on("open:modal-create-grid", () => {
      this.$bvModal.show("modal-create-grid");
    });

    this.getData();
  },
  directives: {
    Ripple,
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
</style>
